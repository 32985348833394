"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  AdvisorTabs: () => AdvisorTabs_default,
  Button: () => Button_default,
  Checkbox: () => Checkbox_default,
  CheckboxGroup: () => CheckboxGroup_default,
  DEFAULT_NO_SELECTION_ID: () => DEFAULT_NO_SELECTION_ID,
  Drawer: () => Drawer_default,
  Icon: () => Icon_exports,
  MGPIFrame: () => MGPIFrame_default,
  Radio: () => Radio_default,
  RadioGroup: () => RadioGroup_default,
  SelectBox: () => SelectBox_default,
  Tabs: () => Tabs_default,
  TextArea: () => TextArea_default,
  TextInput: () => TextInput_default,
  Typography: () => Typography_default,
  useIsSafari: () => useIsSafari,
  useTabControls: () => useTabControls
});
module.exports = __toCommonJS(src_exports);

// src/components/Button/Button.tsx
var React2 = __toESM(require("react"));

// src/hooks.ts
var React = __toESM(require("react"));
var import_classnames = __toESM(require("classnames"));
var useTypographyAttributes = ({
  variant,
  weight,
  fontFamily,
  gutterBottom,
  color,
  darkColor,
  align,
  className
}) => {
  return {
    className: React.useMemo(() => {
      return (0, import_classnames.default)(
        "typography",
        {
          "typography-display": variant === "display",
          "typography-title-2xl": variant === "title-2xl",
          "typography-title-xl": variant === "title-xl",
          "typography-title-lg": variant === "title-lg",
          "typography-title-md": variant === "title-md",
          "typography-title-sm": variant === "title-sm",
          "typography-title-xs": variant === "title-xs",
          "typography-body-2xl": variant === "body-2xl",
          "typography-body-xl": variant === "body-xl",
          "typography-body-lg": variant === "body-lg",
          "typography-body-md": variant === "body",
          "typography-body-sm": variant === "body-sm",
          "typography-body-xs": variant === "body-xs"
        },
        gutterBottom ? {
          "typography-gutter-lg": variant === "title-lg" || variant === "body",
          "typography-gutter-sm": variant === "title-md" || variant === "title-sm" || variant === "title-xs" || variant === "body-xs",
          "typography-gutter-md": variant === "body-sm"
        } : {},
        {
          "typography-thin": weight === "thin",
          "typography-normal": weight === "normal",
          "typography-medium": weight === "medium",
          "typography-semibold": weight === "semibold"
        },
        {
          "typography-font-sans": fontFamily === "sans",
          "typography-font-serif": fontFamily === "serif",
          "typography-font-italic": fontFamily === "italic",
          "typography-font-mono": fontFamily === "mono"
        },
        {
          "typography-text-inherit": color === "inherit",
          "typography-text-primary": color === "primary",
          "typography-text-secondary": color === "secondary",
          "typography-text-tertiary": color === "tertiary",
          "typography-text-quaternary": color === "quaternary",
          "typography-text-accent": color === "accent",
          "typography-text-light": color === "light",
          "typography-text-link": color === "link",
          "typography-text-disabled": color === "disabled",
          "typography-text-danger": color === "danger"
        },
        {
          "dark:typography-text-inherit": darkColor === "inherit",
          "dark:typography-text-primary": darkColor === "primary",
          "dark:typography-text-secondary": darkColor === "secondary",
          "dark:typography-text-tertiary": darkColor === "tertiary",
          "dark:typography-text-quaternary": darkColor === "quaternary",
          "dark:typography-text-accent": darkColor === "accent",
          "dark:typography-text-light": darkColor === "light",
          "dark:typography-text-link": darkColor === "link",
          "dark:typography-text-disabled": darkColor === "disabled",
          "dark:typography-text-danger": darkColor === "danger"
        },
        {
          "typography-text-align-inherit": align === "inherit",
          "typography-text-align-center": align === "center",
          "typography-text-align-justify": align === "justify",
          "typography-text-align-left": align === "left",
          "typography-text-align-right": align === "right"
        },
        className
      );
    }, [
      variant,
      fontFamily,
      weight,
      gutterBottom,
      color,
      align,
      className,
      darkColor
    ])
  };
};
var useButtonAttributes = ({
  size,
  variant,
  fullWidth,
  href,
  className
}) => {
  const BaseComponent = React.useMemo(() => href ? "a" : "button", [href]);
  const computedClassName = React.useMemo(() => {
    return (0, import_classnames.default)(
      "button",
      "button-text",
      {
        "button-sm button-text-sm": size === "sm",
        "button-md button-text-md": size === "md",
        "button-lg button-text-lg": size === "lg"
      },
      {
        "button-primary button-primary-text": variant === "primary",
        "button-primary-dark button-primary-dark-text": variant === "primary-dark",
        "button-secondary button-secondary-text": variant === "secondary",
        "button-secondary-dark button-secondary-dark-text": variant === "secondary-dark",
        "button-tertiary button-tertiary-text": variant === "tertiary",
        "button-tertiary-dark button-tertiary-dark-text": variant === "tertiary-dark",
        "button-danger button-danger-text": variant === "danger",
        "button-disabled button-disabled-text": variant === "disabled"
      },
      {
        "w-full": fullWidth === true
      },
      className
    );
  }, [variant, size, fullWidth, className]);
  return { BaseComponent, className: computedClassName };
};
var useTextInputAttributes = ({
  error,
  type
}) => {
  const className = React.useMemo(() => {
    return (0, import_classnames.default)("TextFieldInput", {
      "TextFieldInput--error": !!error,
      "TextFieldInput--with-icon": type === "currency"
      // for now currency is the only type with an icon to display
    });
  }, [error, type]);
  return {
    className
  };
};
var useTextAreaAttributes = ({
  error
}) => {
  const className = React.useMemo(() => {
    return (0, import_classnames.default)("TextAreaInput", {
      "TextAreaInput--error": !!error
    });
  }, [error]);
  return {
    className
  };
};
var useTabControls = (config) => {
  const [activeTabId, setActiveTabId] = React.useState(
    config.defaultActiveTabId || config.tabs[0].id
  );
  const activeTab = React.useMemo(
    () => config.tabs.find(({ id }) => id === activeTabId) || config.tabs.find(
      ({ id }) => id === config.defaultActiveTabId
    ) || config.tabs[0],
    [activeTabId, config]
  );
  return {
    activeTab,
    setActiveTabId,
    tabs: config.tabs
  };
};
var useSelectBoxSearchInputControls = () => {
  const [keyword, setKeyword] = React.useState("");
  return {
    keyword,
    setKeyword
  };
};
var useIsSafari = () => {
  const [isSafari, setIsSafari] = React.useState(false);
  React.useEffect(() => {
    if (navigator) {
      setIsSafari(
        !!navigator.vendor && navigator.vendor.indexOf("Apple") > -1 && !!navigator.userAgent && navigator.userAgent.indexOf("CriOS") == -1 && navigator.userAgent.indexOf("FxiOS") == -1
      );
    }
  }, [isSafari, setIsSafari]);
  return isSafari;
};

// src/components/Button/Button.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Button = ({
  size = "md",
  disabled = false,
  fullWidth = false,
  href,
  variant,
  children,
  className,
  ...attrs
}, ref) => {
  const { className: computedClassName, BaseComponent } = useButtonAttributes({
    size,
    variant,
    href,
    fullWidth,
    className
  });
  const ButtonBase = BaseComponent;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    ButtonBase,
    {
      ref,
      ...attrs,
      href,
      disabled,
      className: computedClassName,
      children
    }
  );
};
var Button_default = React2.forwardRef(Button);

// src/components/Typography/Typography.tsx
var React3 = __toESM(require("react"));
var import_jsx_runtime2 = require("react/jsx-runtime");
var TypographyRoot = React3.forwardRef(
  ({ as, ...attrs }, ref) => {
    switch (as) {
      case "h1":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h1", { ...attrs, ref });
      case "h2":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h2", { ...attrs, ref });
      case "h3":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h3", { ...attrs, ref });
      case "h4":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h4", { ...attrs, ref });
      case "h5":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h5", { ...attrs, ref });
      case "h6":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("h6", { ...attrs, ref });
      case "span":
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("span", { ...attrs, ref });
      default:
        return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("p", { ...attrs, ref });
    }
  }
);
var Typography = ({
  color = "inherit",
  darkColor,
  fontFamily = "sans",
  variant,
  weight = "normal",
  gutterBottom = false,
  component,
  align = "inherit",
  className,
  ...props
}, ref) => {
  const { className: computedClassName } = useTypographyAttributes({
    color,
    fontFamily,
    variant,
    weight,
    gutterBottom,
    align,
    darkColor,
    className
  });
  const variantMapping = {
    "display": "h1",
    "title-2xl": "h1",
    "title-xl": "h1",
    "title-lg": "h1",
    "title-md": "h2",
    "title-sm": "h3",
    "title-xs": "h4",
    "body-xs": "span"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    TypographyRoot,
    {
      ref,
      as: component || variantMapping[variant] || "p",
      className: computedClassName,
      ...props
    }
  );
};
var Typography_default = React3.forwardRef(Typography);

// src/components/TextInput/TextInput.tsx
var React4 = __toESM(require("react"));
var import_classnames2 = __toESM(require("classnames"));
var import_react_input_mask = __toESM(require("react-input-mask"));
var import_jsx_runtime3 = require("react/jsx-runtime");
var TextInput = ({
  label,
  error,
  required,
  helperText,
  maskOptions,
  type = "text",
  ...props
}, ref) => {
  const { className } = useTextInputAttributes({
    label,
    error,
    required,
    type
  });
  const inputErrorLabelClassName = React4.useMemo(
    () => (0, import_classnames2.default)("TextFieldErrorLabel", { "TextFieldErrorLabel--visible": !!error }),
    [error]
  );
  const helperTextClassName = React4.useMemo(
    () => (0, import_classnames2.default)("TextFieldHelperText", {
      "TextFieldHelperText--visible": !!helperText
    }),
    [error]
  );
  const requiredStatus = React4.useMemo(
    () => required ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", { className: "TextFieldLabel--required", children: "*" }) : "",
    [required]
  );
  const icon = React4.useMemo(() => {
    switch (type) {
      case "currency":
        return "$";
      default:
        return "";
    }
  }, [type]);
  const inputType = React4.useMemo(() => {
    switch (type) {
      case "currency":
      case "number":
        return "number";
      default:
        return "text";
    }
  }, [type]);
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", { className: "TextField", children: [
    /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("label", { className: "TextFieldLabel", children: [
      label,
      requiredStatus
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", { className: "relative", children: [
      icon && /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", { className: "TextFieldInputIcon", children: icon }),
      maskOptions ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        import_react_input_mask.default,
        {
          ...maskOptions,
          ...props,
          type: "text",
          className,
          children: (attrs) => /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
            "input",
            {
              className,
              ...attrs,
              disabled: props.disabled,
              ref
            }
          )
        }
      ) : /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("input", { ref, type: inputType, className, ...props })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", { className: helperTextClassName, children: helperText }),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", { className: inputErrorLabelClassName, children: error })
  ] });
};
var TextInput_default = React4.forwardRef(TextInput);

// src/components/SelectBox/SelectBox.tsx
var React5 = __toESM(require("react"));

// src/components/Icon/index.tsx
var Icon_exports = {};
__export(Icon_exports, {
  Check: () => Check_default,
  CheckOutline: () => CheckOutline_default,
  ChevronDown: () => ChevronDown_default,
  ChevronLeft: () => ChevronLeft_default,
  ChevronRight: () => ChevronRight_default,
  ChevronUp: () => ChevronUp_default,
  Cross: () => Cross_default,
  Minus: () => Minus_default,
  Plus: () => Plus_default
});

// src/helpers.ts
var import_classnames3 = __toESM(require("classnames"));
var getIconColorClassName = (color) => (0, import_classnames3.default)({
  "stroke-black": color === "black",
  "stroke-white": color === "white",
  "stroke-green-1": color === "green-1",
  "stroke-green-2": color === "green-2",
  "stroke-green-3": color === "green-3",
  "stroke-green-4": color === "green-4",
  "stroke-green-5": color === "green-5",
  "stroke-yellow-1": color === "yellow-1",
  "stroke-yellow-2": color === "yellow-2",
  "stroke-yellow-3": color === "yellow-3",
  "stroke-blue-1": color === "blue-1",
  "stroke-blue-2": color === "blue-2",
  "stroke-blue-3": color === "blue-3",
  "stroke-teal-1": color === "teal-1",
  "stroke-teal-2": color === "teal-2",
  "stroke-teal-3": color === "teal-3",
  "stroke-danger": color === "danger",
  "stroke-link": color === "link",
  "stroke-inherit": color === "inherit"
});

// src/components/Icon/ChevronDown.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var ChevronDown = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("path", { d: "M18 9.5L12 15.5L6 9.5", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var ChevronDown_default = ChevronDown;

// src/components/Icon/ChevronRight.tsx
var import_jsx_runtime5 = require("react/jsx-runtime");
var ChevronRight = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("path", { id: "Icon", d: "M10 7L15 12L10 17", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }) });
};
var ChevronRight_default = ChevronRight;

// src/components/Icon/ChevronUp.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
var ChevronUp = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("path", { d: "M6 14.5L12 8.5L18 14.5", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var ChevronUp_default = ChevronUp;

// src/components/Icon/ChevronLeft.tsx
var import_jsx_runtime7 = require("react/jsx-runtime");
var ChevronLeft = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("path", { d: "M14 17L9 12L14 7", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var ChevronLeft_default = ChevronLeft;

// src/components/Icon/Plus.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var Plus = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("path", { d: "M12 6V12M12 12V18M12 12H18M12 12L6 12", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var Plus_default = Plus;

// src/components/Icon/Minus.tsx
var import_jsx_runtime9 = require("react/jsx-runtime");
var Minus = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("path", { d: "M18 12H6", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var Minus_default = Minus;

// src/components/Icon/Check.tsx
var import_jsx_runtime10 = require("react/jsx-runtime");
var Check = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("path", { d: "M5 13L9 17L19 7", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var Check_default = Check;

// src/components/Icon/Cross.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var Cross = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("svg", { className, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("path", { d: "M6 18L18 6M6 6L18 18", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var Cross_default = Cross;

// src/components/Icon/CheckOutline.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
var CheckOutline = ({ color = "black", size = 24 }) => {
  const className = getIconColorClassName(color);
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("svg", { className, fill: "none", width: size, height: size, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("path", { d: "M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) });
};
var CheckOutline_default = CheckOutline;

// src/components/SelectBox/SelectBox.tsx
var import_classnames4 = __toESM(require("classnames"));
var import_react = require("@headlessui/react");

// src/constants.ts
var DEFAULT_NO_SELECTION_ID = "NO_SELECTION";

// src/components/SelectBox/SelectBox.tsx
var import_jsx_runtime13 = require("react/jsx-runtime");
var SelectBox = React5.forwardRef(
  ({
    selected,
    options,
    disabled,
    label,
    error,
    required,
    noSelectionId = DEFAULT_NO_SELECTION_ID,
    noSelectionOptionLabel,
    onChange,
    autoComplete
  }, ref) => {
    const selectBoxButtonClassName = React5.useMemo(
      () => (0, import_classnames4.default)("SelectBoxButton", {
        "--error": !!error
      }),
      [error]
    );
    const selectBoxErrorLabelClassName = React5.useMemo(
      () => (0, import_classnames4.default)("SelectBoxErrorLabel", { "--visible": !!error }),
      [error]
    );
    const requiredStatus = React5.useMemo(
      () => required ? /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("span", { className: "SelectBoxLabelRequired", children: "*" }) : "",
      [required]
    );
    const selectBoxSearchInputClassName = React5.useMemo(
      () => (0, import_classnames4.default)("SelectBoxSearchInput", {
        "--no-selection": (selected == null ? void 0 : selected.id) === noSelectionId
      }),
      [selected, noSelectionId]
    );
    const { keyword, setKeyword } = useSelectBoxSearchInputControls();
    return /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)("div", { "data-testid": "select-box", className: "SelectBox", children: [
      label && /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)("label", { className: "SelectBoxLabel", children: [
        label,
        requiredStatus
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(
        import_react.Combobox,
        {
          ref,
          as: "div",
          value: selected,
          by: "id",
          onChange,
          disabled,
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(import_react.Combobox.Button, { className: selectBoxButtonClassName, children: [
              /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
                import_react.Combobox.Input,
                {
                  className: selectBoxSearchInputClassName,
                  onChange: (e) => setKeyword(e.target.value),
                  displayValue: (opt) => opt.label,
                  autoComplete
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(ChevronDown_default, {}) })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { className: "SelectBoxOptionsPanelAnchor", children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react.Combobox.Options, { className: "SelectBoxOptionsPanel", children: options.filter((opt) => {
              if (keyword) {
                const matchesValue = typeof opt.value === "string" && opt.value.toLowerCase().includes(keyword.toLowerCase());
                const matchesLabel = opt.label.toLowerCase().includes(keyword.toLowerCase());
                return matchesLabel || matchesValue;
              }
              return opt;
            }).map((opt) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
              import_react.Combobox.Option,
              {
                value: opt,
                disabled: opt.disabled,
                children: ({ active, disabled: disabled2, selected: selected2 }) => /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
                  "div",
                  {
                    className: (0, import_classnames4.default)("SelectBoxOption", {
                      "--disabled": disabled2,
                      "--active": active,
                      "--selected": selected2
                    }),
                    children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("div", { className: "SelectBoxOptionLabel", children: opt.id === noSelectionId ? noSelectionOptionLabel || "Select None" : opt.label })
                  }
                )
              },
              opt.id
            )) }) })
          ]
        }
      ),
      error && /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("span", { className: selectBoxErrorLabelClassName, children: error })
    ] });
  }
);
var SelectBox_default = SelectBox;

// src/components/Checkbox/Checkbox.tsx
var React7 = __toESM(require("react"));
var import_classnames6 = __toESM(require("classnames"));

// src/components/CheckboxGroup/CheckboxGroup.tsx
var React6 = __toESM(require("react"));
var import_classnames5 = __toESM(require("classnames"));

// src/components/CheckboxGroup/CheckboxGroupContext.ts
var import_react2 = require("react");
var CheckboxGroupContext = (0, import_react2.createContext)(false);

// src/components/CheckboxGroup/CheckboxGroup.tsx
var import_jsx_runtime14 = require("react/jsx-runtime");
var CheckboxGroup = React6.forwardRef(
  ({ children, name, onChange, error }, ref) => {
    const checkboxGroupErrorLabelClassName = React6.useMemo(
      () => (0, import_classnames5.default)("CheckboxGroupErrorLabel", {
        "CheckboxGroupErrorLabel--visible": !!error
      }),
      [error]
    );
    return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(CheckboxGroupContext.Provider, { value: true, children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("span", { className: "CheckboxGroup-root", "data-testid": "checkbox-group", children: [
      React6.Children.map(
        children,
        (child) => {
          if (React6.isValidElement(child)) {
            return React6.cloneElement(child, {
              name,
              onChange,
              ref
            });
          }
          return child;
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("span", { className: checkboxGroupErrorLabelClassName, children: error })
    ] }) });
  }
);
var CheckboxGroup_default = CheckboxGroup;

// src/components/Checkbox/Checkbox.tsx
var import_jsx_runtime15 = require("react/jsx-runtime");
var Checkbox = React7.forwardRef(
  ({ label, checked, disabled, error, ...attrs }, ref) => {
    const labelTextClassName = React7.useMemo(
      () => (0, import_classnames6.default)("CheckboxLabelText", { "--disabled": disabled }),
      [disabled]
    );
    const checkboxErrorLabelClassName = React7.useMemo(
      () => (0, import_classnames6.default)("CheckboxErrorLabel", { "CheckboxErrorLabel--visible": !!error }),
      [error]
    );
    const isInGroup = React7.useContext(CheckboxGroupContext);
    return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)("div", { className: (0, import_classnames6.default)("Checkbox", { "--in-group": isInGroup }), children: [
      /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)("label", { className: "CheckboxLabel", htmlFor: attrs.id, children: [
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          "input",
          {
            className: "CheckboxInput",
            ref,
            type: "checkbox",
            checked,
            disabled,
            ...attrs
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)("span", { className: labelTextClassName, children: label })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime15.jsx)("span", { className: checkboxErrorLabelClassName, children: error })
    ] });
  }
);
var Checkbox_default = Checkbox;

// src/components/Drawer/Drawer.tsx
var import_react3 = require("@headlessui/react");
var import_jsx_runtime16 = require("react/jsx-runtime");
var Drawer = ({
  children,
  renderHeader,
  renderTitle,
  defaultOpen = false
}) => {
  if (!renderTitle && !renderHeader)
    throw Error(
      `Missing props: 'renderHeader' or 'renderTitle' is required on <Drawer> component.`
    );
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)(
    import_react3.Disclosure,
    {
      "data-testid": "drawer",
      as: "div",
      className: "Drawer",
      defaultOpen,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_react3.Disclosure.Button, { className: "w-full", children: ({ open }) => renderTitle ? /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)("div", { className: "DrawerHeading", children: [
          renderTitle(open),
          /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("div", { className: "DrawerIcon", children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(ChevronDown_default, { size: 24, color: "inherit" }) })
        ] }) : renderHeader ? renderHeader(open) : /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_jsx_runtime16.Fragment, {}) }),
        /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
          import_react3.Transition,
          {
            enter: "transition-opacity duration-300",
            enterFrom: "opacity-0",
            enterTo: "opacity-100",
            unmount: false,
            children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_react3.Disclosure.Panel, { className: "DrawerPanel", unmount: false, children })
          }
        )
      ]
    }
  );
};
var Drawer_default = Drawer;

// src/components/Radio/Radio.tsx
var React8 = __toESM(require("react"));
var import_classnames7 = __toESM(require("classnames"));
var import_jsx_runtime17 = require("react/jsx-runtime");
var Radio = React8.forwardRef(
  ({ value, label, name, disabled, error, checked, onChange, helperText }, ref) => {
    const textLabel = label ?? value;
    const inputId = `${name}-${textLabel}-${value}`;
    const inputClassName = (0, import_classnames7.default)("Radio-input", { "Radio-input--error": !!error });
    return /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)("div", { className: "Radio-root", "data-testid": "radio", children: [
      /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)("div", { className: "Radio-body", children: [
        /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
          "input",
          {
            ref,
            className: inputClassName,
            id: inputId,
            type: "radio",
            checked,
            value,
            disabled,
            name,
            onChange
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("label", { className: "Radio-label", htmlFor: inputId, children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("span", { className: "Radio-text", children: textLabel }) })
      ] }),
      helperText && /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "Radio-helper-label", children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("span", { className: "Radio-helper-text", children: helperText }) })
    ] });
  }
);
var Radio_default = Radio;

// src/components/RadioGroup/RadioGroup.tsx
var React9 = __toESM(require("react"));
var import_classnames8 = __toESM(require("classnames"));
var import_jsx_runtime18 = require("react/jsx-runtime");
var RadioGroup = React9.forwardRef(
  ({ children, name, onChange, error, disabled }, ref) => {
    const radioGroupErrorLabelClassName = React9.useMemo(
      () => (0, import_classnames8.default)("TextAreaErrorLabel", { "TextAreaErrorLabel--visible": !!error }),
      [error]
    );
    return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(
      "fieldset",
      {
        className: "RadioGroup-root",
        "data-testid": "radio-group",
        name,
        disabled,
        children: [
          React9.Children.map(
            children,
            (child) => {
              if (React9.isValidElement(child)) {
                return React9.cloneElement(child, {
                  ...child.props,
                  name,
                  error,
                  onChange,
                  ref
                });
              }
              return child;
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("span", { className: radioGroupErrorLabelClassName, children: error })
        ]
      }
    );
  }
);
var RadioGroup_default = RadioGroup;

// src/components/TextArea/TextArea.tsx
var React10 = __toESM(require("react"));
var import_classnames9 = __toESM(require("classnames"));
var import_jsx_runtime19 = require("react/jsx-runtime");
var TextArea = ({ label, error, required, helperText, ...attrs }, ref) => {
  const { className } = useTextAreaAttributes({ label, error, required });
  const inputErrorLabelClassName = React10.useMemo(
    () => (0, import_classnames9.default)("TextAreaErrorLabel", { "TextAreaErrorLabel--visible": !!error }),
    [error]
  );
  const helperTextClassName = React10.useMemo(
    () => (0, import_classnames9.default)("TextAreaHelperText", { "TextAreaHelperText--visible": !!helperText }),
    [error]
  );
  const requiredStatus = React10.useMemo(
    () => required ? /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("span", { className: "TextAreaLabel--required", children: "*" }) : "",
    [required]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)("div", { className: "TextArea", children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)("label", { className: "TextAreaLabel", children: [
      label,
      requiredStatus
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("textarea", { ref, className, ...attrs }),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("span", { className: helperTextClassName, children: helperText }),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("span", { className: inputErrorLabelClassName, children: error })
  ] });
};
var TextArea_default = React10.forwardRef(TextArea);

// src/components/Tab/Tab.tsx
var import_classnames10 = __toESM(require("classnames"));
var import_jsx_runtime20 = require("react/jsx-runtime");
var Tab = ({
  isActive,
  onClick,
  tabId,
  label
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
    Typography_default,
    {
      variant: "body",
      color: isActive ? "primary" : "secondary",
      weight: isActive ? "medium" : "normal",
      children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
        "button",
        {
          "data-testid": "tab",
          className: (0, import_classnames10.default)(
            "whitespace-nowrap hover:text-green-1",
            isActive ? "tracking-tight" : ""
          ),
          onClick: () => onClick(tabId),
          children: label
        }
      )
    }
  );
};
var Tab_default = Tab;

// src/components/Tabs/Tabs.tsx
var import_jsx_runtime21 = require("react/jsx-runtime");
var Tabs = ({
  children,
  activeTabId,
  tabs,
  onSelectTab
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(
    "div",
    {
      "data-testid": "tabs",
      className: "relative flex h-full flex-1 flex-col p-0",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("header", { className: "flex min-h-15 flex-row space-x-6 overflow-x-auto overflow-y-hidden px-10 py-6", children: tabs.map(({ id, label }) => /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
          Tab_default,
          {
            tabId: id,
            label,
            onClick: onSelectTab,
            isActive: id === activeTabId
          }
        )) }),
        /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_jsx_runtime21.Fragment, { children: children({ activeTabId }) })
      ]
    }
  );
};
var Tabs_default = Tabs;

// src/components/MGPIFrame/MGPIFrame.tsx
var import_react4 = require("react");
var import_jsx_runtime22 = require("react/jsx-runtime");
var MGPIFrame = ({
  samlResponse,
  onLoad,
  onSafariCookieAccepted,
  captureException,
  renderCookieConfirmation,
  cookieConfirmationYOffset,
  mgpSSOUrl,
  mgpSafariCookieURL,
  isSafari
}) => {
  const [mgpCookieSet, setMgpCookieSet] = (0, import_react4.useState)(false);
  const iframeId = (0, import_react4.useMemo)(
    () => `mgp-sso-${(Math.random() + 1).toString(36)}`,
    []
  );
  const cookieConfirmRef = (0, import_react4.useRef)(null);
  const iframeRef = (0, import_react4.useRef)(null);
  const handleLoad = (0, import_react4.useCallback)(() => {
    onLoad();
  }, [onLoad]);
  const handleSafariCookieFix = (0, import_react4.useCallback)(
    (event) => {
      try {
        const { moneyGuideThirdPartyCookieFixSet } = JSON.parse(
          (event == null ? void 0 : event.data) ?? "{}"
        );
        if (moneyGuideThirdPartyCookieFixSet || event.isTrusted) {
          setMgpCookieSet(true);
          onSafariCookieAccepted();
          window.removeEventListener("message", handleSafariCookieFix);
        }
      } catch (err) {
        captureException(err);
      }
    },
    [onSafariCookieAccepted, captureException]
  );
  (0, import_react4.useEffect)(() => {
    if (cookieConfirmRef.current) {
      const y = cookieConfirmRef.current.getBoundingClientRect().top + window.pageYOffset + cookieConfirmationYOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [cookieConfirmRef, cookieConfirmationYOffset]);
  (0, import_react4.useEffect)(() => {
    const iframeElement = iframeRef.current;
    if (iframeElement) {
      iframeElement.addEventListener("load", handleLoad);
    }
    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener("load", handleLoad);
      }
    };
  }, [iframeRef, handleLoad]);
  (0, import_react4.useEffect)(() => {
    if (isSafari) {
      window.addEventListener("message", handleSafariCookieFix);
    }
    return () => {
      if (isSafari) {
        window.removeEventListener("message", handleSafariCookieFix);
      }
    };
  }, [isSafari, handleSafariCookieFix]);
  (0, import_react4.useEffect)(() => {
    if (samlResponse && (!isSafari || mgpCookieSet)) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = mgpSSOUrl;
      form.target = iframeId;
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "SamlResponse";
      input.value = samlResponse;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    }
  }, [samlResponse, iframeId, mgpCookieSet, isSafari, mgpSSOUrl]);
  const onAcceptSafariCookie = (0, import_react4.useCallback)(() => {
    window.open(mgpSafariCookieURL, "_blank");
  }, [mgpSafariCookieURL]);
  if (isSafari && !mgpCookieSet) {
    return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("div", { ref: cookieConfirmRef, children: renderCookieConfirmation({ onAcceptCookie: onAcceptSafariCookie }) });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    "iframe",
    {
      "data-testid": "sso-iframe",
      ref: iframeRef,
      id: iframeId,
      name: iframeId,
      height: "100%",
      width: "100%"
    }
  );
};
var MGPIFrame_default = MGPIFrame;

// src/components/AdvisorTab/AdvisorTab.tsx
var React11 = __toESM(require("react"));
var import_classnames11 = __toESM(require("classnames"));
var import_jsx_runtime23 = require("react/jsx-runtime");
var AdvisorTab = ({
  isActive,
  onClick,
  tabId,
  label
}) => {
  const className = React11.useMemo(
    () => (0, import_classnames11.default)("AdvisorTab", {
      "AdvisorTab--active": isActive
    }),
    [isActive]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    "button",
    {
      "data-testid": "advisor-tab",
      className,
      onClick: () => onClick(tabId),
      children: label
    }
  );
};
var AdvisorTab_default = AdvisorTab;

// src/components/AdvisorTabs/AdvisorTabs.tsx
var import_jsx_runtime24 = require("react/jsx-runtime");
var AdvisorTabs = ({
  children,
  activeTabId,
  tabs,
  onSelectTab
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("div", { "data-testid": "advisor-tabs", className: "AvsisorTabs", children: [
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("header", { className: "AdvisorTabsHeader", children: tabs.map(({ id, label }) => /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
      AdvisorTab_default,
      {
        tabId: id,
        label,
        onClick: onSelectTab,
        isActive: id === activeTabId
      }
    )) }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { children: children({ activeTabId }) })
  ] });
};
var AdvisorTabs_default = AdvisorTabs;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AdvisorTabs,
  Button,
  Checkbox,
  CheckboxGroup,
  DEFAULT_NO_SELECTION_ID,
  Drawer,
  Icon,
  MGPIFrame,
  Radio,
  RadioGroup,
  SelectBox,
  Tabs,
  TextArea,
  TextInput,
  Typography,
  useIsSafari,
  useTabControls
});
